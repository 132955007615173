* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #18181b;
  color: rgb(172,172,183);
}

h1 {
  font-size: 3.75rem;
  margin: 2rem 0 1.68rem 0;
}

h5 {
  font-size: 1.64rem;
  margin: 1.093333333rem 0 .656rem 0;
}

h1, h2, h3, h4, h5, h6 {
  color: #fff;
  font-weight: 400;
  line-height: 110%;
}

a {
  text-decoration: none;
  color: rgb(172,172,183);
}

a:visited {
  color: rgb(172,172,183);
}

@media only screen and (max-width: 768px) {
  h1 {
    font-size: 2.5rem;
  }

  h5 {
    font-size: 1.25rem;
  }
}
